<template>
  <v-list
    expand
    nav
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(item, i) in items">
      <default-list-group
        v-if="item.items"
        :key="`group-${i}`"
        :item="item"
      />

      <default-list-item
        v-else
        :key="`item-${i}`"
        :item="item"
      />
    </template>

    <a
      class="twitterAdminLink"
      href="https://twitter.com/ff14housingeden"
    > <v-list-item>
      <v-list-item-icon>
        <v-icon
          class="mr-1"
          color="#55acee"
        >
          mdi-twitter
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        {{ $t("message.administrator") }}
      </v-list-item-content>
    </v-list-item>
    </a>
    <Estore />
    <v-list-item />
    <v-list-item />
  </v-list>
</template>

<script>
  export default {
    name: 'DefaultList',

    components: {
      DefaultListGroup: () => import('./ListGroup'),
      DefaultListItem: () => import('./ListItem'),
    },

    props: {
      items: {
        type: Array,
        default: () => ([]),
      },
    },
  }
</script>

<style>
.twitterAdminLink {
  text-align: center;
  display: block;
}
</style>
